.profile-page {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background-color: var(--header-background); 
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-page h1 {
  text-align: center;
  color: var(--text-primary);
  margin-bottom: 30px;
  font-size: 24px;
}

.profileform {
  margin-bottom: 25px;
}

.profileform label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--text-primary);
  font-weight: 500;
}

.profileform input[type="text"],
.profileform input[type="file"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
}

.profileform input[type="file"] {
  border: none;
}

.profileButton {
  display: block;
  width: 100%;
  padding: 12px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profileButton:hover {
  background-color: #0056b3;
}

.profile-picture {
  text-align: center;
  margin-bottom: 30px;
}

.profile-picture img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  border: 3px solid #007bff;
}
