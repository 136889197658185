body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Set default theme based on system preference */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #181e27; /* Dark mode background color */
    /* Other dark mode styles */
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: #FFFFFF; /* Light mode background color */
    /* Other light mode styles */
  }
}
