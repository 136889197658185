/* AddArticlePage.css */

.container-article {
  max-width: 50%;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--header-background); 
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0,0,0,0.1); /* Equal shadow on all sides */
}

#h1article {
  color: var(--text-primary);
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
}

.form-article {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.col {
  flex: 1;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
}

.form-group label {
  margin-bottom: 5px;
  color: var(--text-primary);
  font-weight: 600;
}

.form-control 
{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: var(--header-background);
  color: var(--text-primary);
}

.form-group textarea.form-control {
  height: 350px;
  background-color: var(--header-background);
  color: var(--text-primary);
}

.btn {
  padding: 10px 20px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}
.city-suggestions {
  max-height: 200px;
  overflow-y: auto; /* Enable scrolling for long lists */
  width: 100%; /* Match the width of the input field */
  background-color: var(--header-background);  /* Light background for the dropdown */
  border: 1px solid #ced4da; /* Slightly darker border similar to Bootstrap's forms */
  border-radius: 0.25rem; /* Match border-radius of input fields */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  position: absolute;
  z-index: 10; /* Ensure it's above other content */
  top: 100%; /* Position directly below the input field */
  left: 0;
  margin-top: 2px; /* Slight space between input and dropdown */
}

.city-suggestions li {
  padding: 10px; /* Adequate padding for touch targets */
  cursor: pointer; /* Indicate items are clickable */
  list-style-type: none; /* Remove bullet points */
  border-bottom: 1px solid #f0f0f0; /* Slight border between items */
  color: var(--text-primary);
}

.city-suggestions li:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.city-suggestions li:hover, .city-suggestions li:focus {
  background-color: var(--header-background); /* Slight highlight on hover/focus */
}
/* AddArticlePage.css extensions for role suggestions */

.role-suggestions {
  max-height: 200px;
  overflow-y: auto; /* Enable scrolling for long lists */
  width: 100%; /* Match the width of the input field */
  background-color: var(--header-background);  /* Light background for the dropdown */
  border: 1px solid #ced4da; /* Slightly darker border similar to Bootstrap's forms */
  border-radius: 0.25rem; /* Match border-radius of input fields */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  position: absolute;
  z-index: 10; /* Ensure it's above other content */
  top: 100%; /* Position directly below the input field */
  left: 0;
  margin-top: 2px; /* Slight space between input and dropdown */
}

.role-suggestions li {
  padding: 10px; /* Adequate padding for touch targets */
  cursor: pointer; /* Indicate items are clickable */
  list-style-type: none; /* Remove bullet points */
  border-bottom: 1px solid #f0f0f0; /* Slight border between items */
  color: var(--text-primary);
}

.role-suggestions li:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.role-suggestions li:hover, .role-suggestions li:focus {
  background-color: var(--header-background);  /* Slight highlight on hover/focus */
}

.btn:hover {
  background-color: #003d82;
}

.btn-block {
  width: 100%;
  margin-top: 10px;
}
.checkbox-container {
  display: flex;
  align-items: center; /* Align the checkbox and label vertically */
}

.checkbox-container label {
  margin-left: 8px; /* Add some space between the checkbox and the label */
}
.custom-date-picker-input {
  background-color: var(--header-background);
  color: var(--text-primary);
  border-radius: 0.25rem;
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.calendar-icon {
  color: #6c757d;
}
.editor-toolbar button, .CodeMirror-scroll, 
.CodeMirror    {
  background: var(--header-background) !important;
  background-color: var(--header-background) ;
  color: var(--text-primary) ;
}
/* .CodeMirror-cursor{
  color:red;
} */
       
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
}
