/* Header.css */
.site-header {
    background-color: var(--header-background); 
    color: var(--text-primary);
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensure the header is above other content */
    width: 100%;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1% 15%;
    border-bottom: 1px solid var(--border-color);
    

  }

  
  .nav-links,
  .auth-links,
  .theme-switcher{
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li,
  .auth-links li {
    margin-right: 20px;
   
  }
  
  .nav-links li:last-child,
  .auth-links li:last-child {
    margin-right: 0;
   
  }
  
  .nav-links a,
  .auth-links a {
    color: var(--text-primary);
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 15px;
    
  }
  .theme-switcher{
    font-size: 25px;
  }
  .nav-links a:hover,
  .auth-links a:hover {
    color: #2E7D32; /* Light blue for hover state */
    font-weight: bold;
  }
  
  .auth-links .theme-switcher{
    font-weight: bold;
  }
  .auth-links {
    /* Style to match your nav links, if necessary */
    margin-left: 50px; /* Creates visual separation */
  }
  
  /* Adjust the margin or padding directly on the ThemeSwitcher component's root element if needed */
  .auth-links li {
    display: flex;
    align-items: center; /* Ensures vertical alignment */
    font-size: 15px;
  }

  /* logo */

.nav-brand a img.website-logo {
    height: 55px; /* Adjust based on your logo's size */
    width: auto;
    margin-right: 20px; /* Creates space between the logo and the nav links */
  }
  
  
  /* Ensure your existing styles follow */
  /* Header.css additions */
/* Ensures the entire brand link, including image and text, has no underline */
.brand-link {
    display: flex;
    align-items: center;
    text-decoration: none; /* Removes underline from the link */
    color: var(--text-primary); /* Sets the text color */
  }
  
  .website-logo {
    margin-top: 3%;
    height: 50px; /* Logo size, adjust as necessary */
    width: auto;
    margin-right: 10px; /* Space between logo and text */
  }
  
  .website-name {
    font-size: 40px; /* Adjust based on your design */
    font-weight: bold; /* Bold text */
    vertical-align: middle; /* Center text vertically, may be redundant with flex alignment */
    display: table-caption;
    margin: 0; /* Remove default margins */
    line-height: 20px; /* Adjust line height to match logo height for vertical centering */
    margin-bottom: 20px;
    color:#81C784;
  }
  .logout-button {
    background-color: #007bff; /* Red color */
    color: var(--text-primary);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .logout-button:hover {
    background-color: #0259b6d1; /* Darker red on hover */
  }
  /* for dropdown */
  /* For the dropdown */
/* Base container for the avatar and dropdown */
.avatar-container {
  position: relative;
  display: inline-block; /* or flex, depending on layout */
  color: var(--text-primary) !important;
}

/* Style for the dropdown menu */
.dropdown-menu {
  position: absolute;
  top: 100%; /* Positioned right below the avatar container */
  right: 0;
  background-color: var(--header-background); 
  border: 1px solid #e6e6e6; 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 8px; /* Rounded corners */
  width: 200px; /* Adjust width as needed */
  z-index: 1001; /* Ensure it's above other elements */
  overflow: hidden; /* Hide overflow for rounded corners */
  margin:0% 1%;
  margin-top: 5px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease, visibility 0.5s;
  
}
.avatar-container:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}


/* Style for each dropdown item */
.dropdown-item {
  padding: 12px 0.1px; /* Increase padding for a spacious feel */
  font-size: 14px; /* Adjust font size for readability */
  color: var(--text-primary);  /* Dark grey color for text */
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out; /*Smooth background transition */
  text-decoration: none; /* Remove underline from links */
  display: block; /* Ensure it takes the full width */
  cursor:pointer;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* A more modern font stack */
  
}

/* Style for icons in dropdown items, assuming you use a span or i tag */
.dropdown-item i {
  margin-left: 20px; /* Space between icon and text */
  /* color: #6c757d; Slightly muted color for icons */
  font-size: 16px; /* Adjust icon size as needed */
}

/* Hover effect */
.dropdown-item:hover {
  /* background-color: #f8f9fa; A very light grey for hover */
  color: #81C784;
}

/* Style for the avatar */
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover; /* Ensure images are scaled correctly */
}

/* Remove border styling to make it look like a stack of text */
/* .dropdown-item {
  border-bottom: none;
} */

/* Additional styles to improve the visual hierarchy and spacing */
.dropdown-item:not(:last-child) {
  margin-bottom: 4px; /* Spacing between items */
}

/* Optional: Add a separator line between items */
.dropdown-item:not(:last-child)::after {
  content: '';
  display: block;
  height: 1px;
  background-color: var(--header-background);  /* Light color for the separator */
  margin-top: 12px; /* Spacing above the separator */
}

  /* Responsive styles for mobile screens */
/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .avatar-container {
    text-align: center; /* This centers inline-block elements horizontally */
  }
  dropdown-menu {
    width: 200px; /* Adjust width as needed */
    left: 50%; /* Position the left edge of the dropdown to the center of the container */
    transform: translateX(-50%); /* Move the dropdown back by half its width to center it */
   
  }
  
  .navbar {
    flex-direction: column;
    align-items: center; /* Center-align items for a cleaner look */
    padding: 10px; /* Reduce padding for more screen space */
  }

  .nav-links,
  .auth-links {
    flex-direction: row; /* Stack links vertically */
    align-items: center; /* Center-align links */
    width: 100%;
    gap: 10px;
    justify-content: center;
    padding-top: 2px; /* Add some space between the logo and the links */
  }

  .nav-links li,
  .auth-links li {
    margin: 5px 0; /* Reduce margin and apply it evenly for a compact look */
  }

  .nav-links a,
  .auth-links a {
    font-size: 16px; /* Adjust font size for better readability on smaller screens */
  }

  .website-name {
    font-size: 24px; /* Adjust font size for smaller screens */
    margin-top: 10px; /* Add some space between the logo and the site name */
  }

  .logout-button {
    padding: 8px 12px; /* Adjust padding for a smaller button */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}
