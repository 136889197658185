/* LoginPage.css */

  .tt-loginpages-wrapper {
    width: 100%;
    max-width: 400px;
    margin: auto;
    background: var(--header-background); 
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    margin-top: 10%;
  }
  
  .tt-block-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .tt-block-title img {
    height: 100px; /* Adjust size according to your logo */
    display: block;
    margin: 0 auto;
  }
  
  .tt-title {
    font-size: 24px;
    color: var(--text-primary);
    font-weight: bold;
    margin-top: 20px;
  }
  
  .tt-description {
    color: var(--text-primary);
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .form-default .form-group {
    margin-bottom: 15px;
  }
  
  .form-default label {
    font-size: 16px;
    color: var(--text-primary);
    margin-bottom: 5px;
    display: block;
  }
  
  .form-default input[type='text'],
  .form-default input[type='password'] {
    /* width: 100%; */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-default .checkbox-group {
    display: flex;
    align-items: center;
  }
  
  .form-default .checkbox-group input[type='checkbox'] {
    margin-right: 10px;
  }
  
  .form-default .checkbox-group label {
    font-size: 14px;
    color: var(--text-primary);
  }
  
  .form-default .btn-secondary {
    background-color: #0084ff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .form-default .btn-secondary:hover {
    background-color: #006ae0;
  }
  
  .tt-underline {
    color: var(--text-primary);
    text-decoration: underline;
  }
  
  .social-buttons {
    margin: 20px 0;
  }
  
  .social-buttons a {
    display: inline-block;
    width: calc(50% - 10px);
    text-align: center;
    margin-right: 10px;
    padding: 10px;
    color: var(--text-primary);
    border-radius: 4px;
    text-decoration: none;
  }
  
  .social-buttons a:last-child {
    margin-right: 0;
  }
  
  .social-buttons .btn-color01 {
    background-color: #3b5998; /* Facebook color */
  }
  
  .social-buttons .btn-color02 {
    background-color: #1da1f2; /* Twitter color */
  }
  
  .tt-notes {
    font-size: 12px;
    color: var(--text-primary);
    text-align: center;
    margin-top: 20px;
  }
  
  .alert-danger {
  color: red;
  /* background-color: red; */
  /* padding: 10px;
  border-radius: 5px;
  margin: 10px 0; */
}
/* Mobile Styles */
@media (max-width: 480px) {
  .tt-loginpages-wrapper {
    padding: 20px;
    margin: 5%;
    max-width: 300px;
  }

  .tt-block-title img {
    height: 80px; /* Adjust logo size for smaller screens */
  }

  .tt-title {
    font-size: 20px; /* Adjust title font size for smaller screens */
  }

  .tt-description {
    font-size: 14px; /* Adjust description font size for smaller screens */
  }

  .form-default label {
    font-size: 14px; /* Adjust form label font size for smaller screens */
  }

  .form-default input[type='text'],
  .form-default input[type='password'] {
    font-size: 14px; /* Adjust input font size for smaller screens */
  }

  .form-default .checkbox-group label {
    font-size: 12px; /* Adjust checkbox label font size for smaller screens */
  }

  .form-default .btn-secondary {
    font-size: 14px; /* Adjust button font size for smaller screens */
  }

  .social-buttons a {
    width: calc(100% - 20px); /* Adjust social button width for smaller screens */
    margin-bottom: 10px; /* Add margin between buttons */
  }

  .tt-notes {
    font-size: 10px; /* Adjust notes font size for smaller screens */
  }
}
