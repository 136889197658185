.filter-bar-container {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background-color: var(--header-background); 
  /* margin: 0% 2%; */
}

.filter-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1% 15%;
  background-color: var(--header-background); 
  margin: 0% 3%;
}

  
  .filter-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: calc(25% - 22px); /* Adjusts width accounting for gap; tweak as necessary */
    background-color: var(--header-background); 
    color: var(--text-primary);
  }
  
  .filter-search-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff; /* Button color */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
    appearance: auto;

  }
  
  .filter-search-btn:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .filter-input {
      width: calc(100% - 22px); /* Adjust for smaller screens */
    }
  
    .filter-search-btn {
      width: 100%; /* Full width on smaller screens */
      text-align: center;
    }
  }
  