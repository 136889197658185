/* General page styles */
  /* Signup Page specific styles */
/* General page styles */

  .heelop{
    color: var(--text-primary);
  }
  .sp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 83vh;
    background-color: var(--header-background); 
  }
  
  .sp-tt-loginpages-wrapper {
    width: 100%;
    max-width: 400px;
    margin: auto;
    background: var(--header-background); 
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  
  }
  
  .sp-tt-block-title {
    text-align: center;
    margin-bottom: 30px;
    text-decoration: none;
  }
  
  .sp-tt-block-title img {
    max-width: 100px;
    height: 100px; /* Adjust size according to your logo */
    display: block;
    margin: 0 auto;
    
  }
  
  .sp-tt-title {
    font-size: 24px;
    color: var(--text-primary);
    margin-top: 10px;
  }
  
  .sp-tt-description {
    font-size: 16px;
    color: var(--text-primary);
    margin-top: 5px;
  }
  
  .sp-form-default .sp-form-group {
    margin-bottom: 15px;
  }
  
  .sp-form-default label {
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
    color: var(--text-primary);
  }
  
  .sp-form-default input[type="text"],
  .sp-form-default input[type="email"],
  .sp-form-default input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .sp-btn-block {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .sp-btn-block:hover {
    background-color: #0056b3;
  }
  
  .sp-tt-notes {
    font-size: 14px;
    color: var(--text-primary);
    margin-top: 20px;
    text-align: center;
  }
  
  .sp-tt-underline {
    text-decoration: underline;
    color: #007bff;
  }
  
  a.sp-tt-underline:hover {
    color: #0056b3;
  }
  .alert-success{
    color:green;
  }
  
  /* Responsive adjustments */
@media (max-width: 768px) {
  .sp-container {
    height: auto;
    min-height: 100vh;
    /* padding: 20px; */
  }

  .sp-tt-loginpages-wrapper {
    padding: 20px;
    margin: 5%;
  }

  .sp-tt-block-title img {
    height: 80px;
  }

  .sp-tt-title {
    font-size: 20px;
  }

  .sp-tt-description {
    font-size: 14px;
  }

  .sp-form-default label {
    font-size: 14px;
  }

  .sp-form-default input[type="text"],
  .sp-form-default input[type="email"],
  .sp-form-default input[type="password"] {
    font-size: 14px;
  }

  .sp-btn-block {
    font-size: 14px;
  }

  .sp-tt-notes {
    font-size: 12px;
  }
}
