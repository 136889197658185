/* ArticleDetail.css */
.article-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--header-background); 
  color: var(--text-primary);
}

.article-title {
  margin-bottom: 15px;
  color: #007bff;
  font-size: 2.5rem;
}

.article-meta {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 1rem;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.article-title, .article-meta, .article-content, .article-tags {
  margin-bottom: 15px;
}

.article-meta span, .article-tags {
  display: block; /* or 'inline-block' based on preference */
  margin-bottom: 5px;
}
.article-meta span {
  margin-right: 10px; /* Adjust spacing between meta info as needed */
  white-space: nowrap; /* Prevent wrapping */
}

.article-content {
  line-height: 1.7;
  text-align: justify;
  font-size: 1.1rem;
}
.article-container {
  max-width: 100%;
  padding: 30px;
  margin: 1% 15%;
  box-shadow: none; /* Adjust based on your design */
  background-color: var(--article-background); /* Light background color */
}

.article-title, .article-meta, .article-content, .article-discussion {
  margin-bottom: 20px;
}

.article-discussion {
  padding-top: 20px;
  border-top: 2px solid var(--border-color); /* Subtle separator */
}

.article-discussion h2 {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .article-container {
    padding: 15px; /* More compact padding */
    margin: 15px auto; /* Slightly more margin */
    border-radius: 5px; /* Smoother border-radius */
    background-color: var(--header-background);  /* Slightly different background color for a fresh look */
    overflow: hidden;
  }

  .article-title {
    font-size: 1.8rem; /* Slightly smaller font size */
    margin-bottom: 12px; /* Adjusted margin */
    color: #0056b3; /* Different color for a pop of contrast */
  }

  .article-meta {
    font-size: 0.85rem; /* Smaller font size for meta information */
    margin-bottom: 15px; /* More space between meta and content */
    color: #555; /* Slightly darker color for better readability */
    /* display: row; */
    display: inline-block;
  }

  .article-meta span {
    margin-bottom: 4px; /* Tighter spacing between meta items */
  }

  .article-content {
    font-size: 1rem; /* Adjusted font size for content */
    line-height: 1.6; /* Adjusted line height for better readability */
    text-align: left; /* Align text to the left for a cleaner look */
    color: #333; /* Darker color for content for better contrast */
  }

  .article-discussion {
    padding-top: 15px; /* Adjusted padding */
    border-top: 1px solid var(--border-color); /* Lighter border for a subtle separation */
  }

  .article-discussion h2 {
    font-size: 1.4rem; /* Adjusted font size for discussion title */
    color: #007bff; /* Matching color with the article title for consistency */
    margin-bottom: 8px; /* Adjusted margin */
  }
}
