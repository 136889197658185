/* ArticlePreview.css */
.article-preview {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  padding: 14px;
  border-radius: 8px;
  background-color: var(--article-preview);
  transition: box-shadow 0.3s;
  color: var(--text-primary);
  margin: 0 auto;
  max-width: 70%;
}

.article-preview:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.article-preview-link {
  text-decoration: none;
  color: inherit;
  margin-bottom: 20px;
  display: inline; /* Changed from block to inline */
  transition: transform 0.2s;
}

.article-preview-link:hover {
  transform: translateY(-5px);
}

/* Assuming you have 5 pieces of information as in the screenshot */
.article-preview > div {
  flex: 1; /* Each child div will take up equal space */
  padding: 0 10px; /* Add some padding on the sides */
  text-align: left; /* Align the text to the left */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* You might need to adjust this depending on the content and number of columns */
.article-preview > div:nth-child(2) {
  flex: 2; /* Give the second child more space if needed */
}
.article-preview:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.preview-title, .preview-detail {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview-title {
  font-size: 1.1em;
  font-weight: bold;
  height: 1.4em; /* Fixed height for title */
  line-height: 1.4em; /* Ensure text is vertically centered */
}

.preview-detail {
  font-size: 0.9em;
  color: var(--text-primary);
  margin-top: 5px;
  height: 1.2em; /* Fixed height for details */
  line-height: 1.2em; /* Ensure text is vertically centered */
}


.article-preview:first-of-type {
  margin-top: 20px;
}
.article-preview:last-of-type {
  margin-bottom: 10px; /* This will add a margin below the last .article-preview element */
}

@media (max-width: 768px) {
  .article-preview {
    flex-direction: column; /* Stack the content vertically */
    align-items: flex-start; /* Align items to the start for a cleaner look */
    padding:10px;
  }

  .article-preview > div {
    flex: none; /* Remove the flex property so each child takes up full width */
    padding: 5px 0; /* Adjust padding for a compact layout */
    text-align: left; /* Ensure text is aligned to the left */
    border-radius: 20px;
  }

  .preview-title,
  .preview-detail {
    white-space: normal; /* Allow text to wrap */
    overflow: visible; /* Show overflowed text */
    text-overflow: clip; /* Remove ellipsis */
  }

  .preview-title {
    font-size: 1em; /* Adjust font size for smaller screens */
    height: auto; /* Remove fixed height */
    line-height: normal; /* Use normal line height */
  }

  .preview-detail {
    font-size: 0.8em; /* Adjust font size for smaller screens */
    height: auto; /* Remove fixed height */
    line-height: normal; /* Use normal line height */
    margin-top: 2px; /* Adjust margin for tighter spacing */
  }
}
