
/* styles.css */
:root {
  --color-dark: #181e27; /* Black */
  --color-light: #FFFFFf; /* Yellowish White */
  --text-color-dark: #FFFFFF; /* White for dark mode text */
  --text-color-light: #181e27; /* Black for light mode text */
  --text-primary: var(--text-color-light); 

  --header-background-dark: #181e27; /* Dark mode header background */
  --header-background-light: #FFFFFf /* Light mode header background */
  --footer-background-dark: #181e27; /* Dark mode footer background */
  --footer-background-light:#FFFFFf /* Light mode footer background */
  --article-background-dark: #181e27; /* Dark mode article background */
  --article-background-light: #FFFFFf; /* Light mode article background */
  --border-color-dark: #2e3b47;
  --border-color-light: #e2e7ea;
  /* Dark Mode Button Colors */
  --button-background-dark: #4cae4c;
  --button-hover-background-dark: #449d44;

  /* Light Mode Button Colors */
  --button-background-light: #5cb85c;
  --button-hover-background-light: #4cae4c;
  --article-preview-background-dark: #222a36;
  --article-preview-background-light: #FFFFFf;
}

body.dark-mode {
  --text-primary: var(--text-color-dark); /* White text in dark mode */
  --button-background: var(--button-background-dark);
  --button-hover-background: var(--button-hover-background-dark);
  --input-border: #555; /* Darker border for inputs in dark mode */

  --header-background: var(--header-background-dark);
  --footer-background: var(--footer-background-dark);
  --article-background: var(--article-background-dark);
  --border-color: var(--border-color-dark);
  --article-preview: var(--article-preview-background-dark);
}

body.light-mode {
  --text-primary: var(--text-color-light); /* Black text in light mode */
  --button-background: var(--button-background-light);
  --button-hover-background: var(--button-hover-background-light);
  --input-border: #ddd; /* Light border for inputs in light mode */

  --header-background: var(--header-background-light);
  --footer-background: var(--footer-background-light);
  --article-background: var(--article-background-light);
  --border-color: var(--border-color-light);
  --article-preview: var(--article-preview-background-light);
}


/* body {
  color: var(--text-primary);
} */


.header {
  background-color: var(--button-background); /* Use button color variable for dynamic theme change */
}


.article-preview button,
.add-article-button {
  background-color: var(--button-background);
  color: var(--text-primary);
}

.article-preview button:hover,
.add-article-button:hover {
  background-color: var(--button-hover-background);
}

/* Adjust input and textarea border colors for theme */
.add-article-form input,
.add-article-form textarea{
  border: 1px solid var(--input-border);
}

body.dark-mode, 
body.dark-mode .header, 
body.dark-mode .article-preview h3, 
body.dark-mode .article-preview p{
  color: var(--text-color-dark); /* Ensures text is white in dark mode */
}

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Krub', sans-serif;
  }
  
  /* Header.css */
  .header {
    background-color: var(--header-background); 
    color: var(--text-primary);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .header h1 {
    margin: 0;
    font-size: 24px;
  }

  
  /* ArticleList.css */
  .article-list {
    padding: 20px;
  }
  
  /* ArticlePreview.css */
  .article-preview {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .article-preview h3 {
    margin: 0 0 5px 0;
    color: var(--text-primary);
    font-size: 20px;
  }
  
  .article-preview p {
    font-size: 16px;
    color: var(--text-primary);
  }
  
  .article-preview button {
    background-color: #5cb85c;
    color: var(--text-primary);
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .article-preview button:hover {
    background-color: #4cae4c;
  }
  

 /* AddArticleButton.css */
.add-article-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 20px 0;
  }
  
  .add-article-button:hover {
    background-color: #0056b3;
  }
  
  
  
  
  /* Style your form and input fields as well */
  
/* ThemeSwitcher */ /* 
.theme-switcher {
  cursor: pointer;
  padding: 10px 20px;
  margin: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.theme-switcher:hover {
  background-color: #e2e2e2;
} */
/* ThemeSwitcher.css */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


footer {
  margin-top: auto; /* Pushes the footer to the bottom of the container */
}

/* like alert */
.login-message {
  color: red;
  margin-top: 10px;
  font-size: 0.9rem;
}

/* pagination */
.pagination {
  display: flex;
  justify-content: center; /* Centers the pagination */
  margin: 10px; /* Adds some space above the pagination */
  padding: 0;
  list-style: none; /* Removes default list styling */
}

.pagination button {
  background-color: var(--button-background); /* Light background for a more natural look */
  color: var(--text-primary);
  border: 1px solid #dee2e6; /* Light gray border */
  padding: 5px 10px; /* Adjusts padding for a better appearance */
  margin: 0 5px; /* Adds space between buttons */
  border-radius: 5px; /* Rounds the corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition: all 0.3s ease; /* Smooth transition for all properties */
}

.pagination button:hover {
  background-color: #e2e6ea; /* Slightly darker background on hover */
  border-color: #dae0e5; /* Slightly darker border on hover */
}

.pagination .active button {
  background-color: #007bff; /* Blue background for active button */
  color: white; /* White text for active button */
  border-color: #007bff; /* Blue border for active button */
}

/* theme swithcer */
/* ThemeSwitcher.css */
