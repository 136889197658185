/* html, body {
  height: 100%;
  margin: 0; 
} */

#page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Make sure to at least be the full height of the viewport */
}

.content-wrap {
  flex:1 ; /* Ensures that the container takes up all the space up to the footer */
}

.site-footer {
  background-color: var(--footer-background);
  color: var(--text-primary);
  padding: 10px 15%; /* Reduced padding for a more compact footer */
  width: 100%; /* Ensure footer extends full width */
  border-top: 1px solid var(--border-color);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 0 15px; */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Wrap on smaller screens */
}

.footer-section {
  margin: 0 10px; /* Adjusted margin for compact spacing */
}

.footer-section h4,
.footer-section p {
  margin: 5px 0; /* Reduced top and bottom margin for headings and paragraphs */
}

.footer-section a {
  color: var(--text-primary);
  text-decoration: none;
  /* margin: 0 5px;  */
  /* Reduced margin between links */
}
.footer-section a:nth-of-type(1) {
  margin: 0; /* No margin for the first link */
}

.footer-section a:not(:nth-of-type(1)) {
  margin-left: 5px; /* Add left margin to all links except the first one */
}


.footer-section a:hover {
  text-decoration: underline;
}

/* Additional adjustment for mobile screens */
/* Additional adjustment for mobile screens */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center; /* Center-align text for better readability on small screens */
  }

  .footer-section {
    margin: 10px 0; /* Increase vertical spacing on smaller screens for better separation */
  }

  .footer-section h4,
  .footer-section p {
    margin: 5px 0; /* Keep reduced top and bottom margin for headings and paragraphs */
  }

  .footer-section a {
    display: inline-block; /* Make links block-level elements for better tap targets */
    margin: 5px; /* Add some margin around links for easier tapping */
  }
}
